import { Component } from '@angular/core';

@Component({
  selector: 'ds-logout-page',
  styleUrls: ['./logout-page.component.scss'],
  templateUrl: './logout-page.component.html'
})
export class LogoutPageComponent {

}
