<div>
  <fieldset class="d-flex">
    <legend *ngIf="!model.repeatable" [id]="'legend_' + model.id" [ngClass]="[getClass('element', 'label'), getClass('grid', 'label')]">
      {{model.placeholder}} <span *ngIf="model.required">*</span>
    </legend>
    <ds-number-picker
      tabindex="1"
      [id]="model.id + '_year'"
      [disabled]="model.disabled"
      [min]="minYear"
      [max]="maxYear"
      [name]="'year'"
      [size]="4"
      [(ngModel)]="initialYear"
      [value]="year"
      [invalid]="showErrorMessages"
      [placeholder]='yearPlaceholder'
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)"
    ></ds-number-picker>
  </fieldset>
</div>

<div class="clearfix"></div>
