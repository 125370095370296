import {Component, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigurationDataService} from '../../core/data/configuration-data.service';
import {getFirstSucceededRemoteDataPayload} from '../../core/shared/operators';
import {map} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../../config/app-config.interface';

@Component({
  selector: 'ubmz-branch-info',
  templateUrl: './branch-info.component.html',
  styleUrls: ['./branch-info.component.scss']
})

export class BranchInfoComponent {
  protected beBuildTime: Observable<string>;
  protected beBuildEnvironment: Observable<string>;
  protected beBuildBranchName: Observable<string>;
  protected beBuildTag: Observable<string>;
  protected beBuildRequestedBy: Observable<string>;

  protected feBuildTime: string;
  protected feBuildEnvironment: string;
  protected feBuildBranchName: string;
  protected feBuildTag: string;
  protected feBuildRequestedBy: string;


  constructor(
      private configurationService: ConfigurationDataService,
      @Inject(APP_CONFIG) protected appConfig: AppConfig
  ) {
    this.beBuildTime = this.getBuildTime();
    this.beBuildEnvironment = this.getBuildEnv();
    this.beBuildTag = this.getBuildTag();
    this.beBuildBranchName = this.getBuildBranch();
    this.beBuildRequestedBy = this.getBuildRequester();

    this.feBuildTime = this.appConfig.buildInfo.time;
    this.feBuildEnvironment = this.appConfig.buildInfo.env;
    this.feBuildBranchName = this.appConfig.buildInfo.branch;
    this.feBuildTag = this.appConfig.buildInfo.tag;
    this.feBuildRequestedBy = this.appConfig.buildInfo.requestedBy;
  }

  private getBuildTime(): Observable<string> {
    return this.configurationService.findByPropertyName('build.info.buildtime').pipe(
      getFirstSucceededRemoteDataPayload(),
      map((configurationProperty) => configurationProperty.values[0]),
    );
  }

  private getBuildTag(): Observable<string> {
    return this.configurationService.findByPropertyName('build.info.buildtag').pipe(
      getFirstSucceededRemoteDataPayload(),
      map((configurationProperty) => configurationProperty.values[0]),
    );
  }

  private getBuildBranch(): Observable<string> {
    return this.configurationService.findByPropertyName('build.info.branch').pipe(
      getFirstSucceededRemoteDataPayload(),
      map((configurationProperty) => configurationProperty.values[0]),
    );
  }

  private getBuildEnv(): Observable<string> {
    return this.configurationService.findByPropertyName('build.info.env').pipe(
      getFirstSucceededRemoteDataPayload(),
      map((configurationProperty) => configurationProperty.values[0]),
    );
  }

  private getBuildRequester(): Observable<string> {
    return this.configurationService.findByPropertyName('build.info.requestedBy').pipe(
      getFirstSucceededRemoteDataPayload(),
      map((configurationProperty) => configurationProperty.values[0]),
    );
  }
}
