<div class="container" *ngVar="(itemRD$ | async) as itemRD">
  <div class="item-page" *ngIf="itemRD?.hasSucceeded" @fadeInOut>
    <div *ngIf="itemRD?.payload as item">
      <ds-item-alerts [item]="item"></ds-item-alerts>
      <ds-item-versions-notice [item]="item"></ds-item-versions-notice>
      <ds-view-tracker [object]="item"></ds-view-tracker>
      <div *ngIf="!item.isWithdrawn || (isAdmin$|async)" class="crazy-item-info">
        <div class="d-flex flex-row">
          <ds-item-page-title-field class="mr-auto" [item]="item"></ds-item-page-title-field>
          <ds-dso-edit-menu></ds-dso-edit-menu>
        </div>
        <div class="simple-view-link my-3" *ngIf="!fromSubmissionObject">
          <a class="btn btn-outline-primary" [routerLink]="[(itemPageRoute$ | async)]">
            {{"item.page.link.simple" | translate}}
          </a>
        </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <tbody>
              <ng-container *ngFor="let entry of crazyData | dsObjNgFor">
                <tr *ngIf="entry.key === 'Rest (JSON)'">
                  <td>{{entry.key}}</td>
                  <td><a href="{{restProtocol}}://{{restHost}}{{restNameSpace}}/api/core/items/{{item.uuid}}" target="_blank">
                    {{restProtocol}}://{{restHost}}{{restNameSpace}}/api/core/items/{{item.uuid}}</a>
                  </td>
                </tr>
                <tr *ngIf="entry.key !== 'Rest (JSON)'">
                  <td>{{entry.key}}</td>
                  <td><a href="{{restProtocol}}://{{restHost}}{{restNameSpace}}/oai/request?verb=GetRecord&identifier=oai:openscience.ub.uni-mainz.de:{{item.handle}}&metadataPrefix={{entry.value}}" target="_blank">
                    {{restProtocol}}://{{restHost}}{{restNameSpace}}/oai/request?verb=GetRecord&identifier=oai:openscience.ub.uni-mainz.de:{{item.handle}}&metadataPrefix={{entry.value}}</a>
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td>{{'item.page.filesection.uuid' | translate}}</td>
                <td>{{item.uuid}}</td>
              </tr>
              <tr *ngFor="let identifier of (identifiers$ | async)">
                <td *ngIf="identifier.identifierType === 'doi' && identifier.identifierStatus === 'TO_BE_REGISTERED'">{{'item.page.provisionalDoi' | translate}}</td>
                <td *ngIf="identifier.identifierType === 'doi' && identifier.identifierStatus === 'TO_BE_REGISTERED'">{{identifier.value}}</td>
              </tr>
              <tr>
                <td>{{'item.page.isArchived' | translate}}</td>
                <td>{{item.isArchived}}</td>
              </tr>
              <tr>
                <td>{{'item.page.isWithdrawn' | translate}}</td>
                <td>{{item.isWithdrawn}}</td>
              </tr>
              <tr>
                <td>{{'item.page.isDiscoverable' | translate}}</td>
                <td>{{item.isDiscoverable}}</td>
              </tr>
              <tr>
                <td>{{'item.page.embargoItem'| translate}}</td>
                <td>
                  <span *ngIf="(getItemResourcePolicies() | async)?.length > 0">
                    <div *ngFor="let entry of (getItemResourcePolicies() | async)">
                      <span *ngIf="entry.policy.startDate">{{'item.page.policy.startDate' | translate}} {{entry.policy.startDate}}</span>
                      <span *ngIf="entry.policy.endDate">{{'item.page.policy.endDate' | translate}} {{entry.policy.endDate}}</span>
                    </div>
                  </span>
                </td>
              </tr>
              <tr>
                <td>{{'item.page.embargoFile'| translate}}</td>
                <td>
                  <span *ngIf="crazyFileSectionData.originalFilePolicies.length > 0">
                    <div *ngFor="let entry of crazyFileSectionData.originalFilePolicies">
                      <span *ngIf="entry.policy.startDate">{{'item.page.policy.startDate' | translate}} {{entry.policy.startDate}}</span>
                      <span *ngIf="entry.policy.endDate">{{'item.page.policy.endDate' | translate}} {{entry.policy.endDate}}</span>
                    </div>
                  </span>
                </td>
              </tr>
              <tr>
                <td>{{'item.page.submitter' | translate}}</td>
                <td>{{submitter}}</td>
              </tr>
              <tr>
                <td>{{'item.page.lastModified' | translate}}</td>
                <td>{{item.lastModified}}</td>
              </tr>
              <tr>
                <td>{{'item.page.numberAttachedFiles' | translate}}</td>
                <td>{{'item.page.attachedFiles.originals' | translate}} {{crazyFileSectionData.originals}}<br>
                    {{'item.page.attachedFiles.licenses' | translate}} {{crazyFileSectionData.licenses}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table table-striped">
            <tbody>
              <ng-container *ngFor="let mdEntry of (metadata$ | async) | keyvalue">
              <tr *ngFor="let mdValue of mdEntry.value">
                <td>{{mdEntry.key}}</td>
                <td>{{mdValue.value}}</td>
                <td>{{mdValue.language}}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
        <ds-item-page-crazy-file-section [item]="item" (crazyFileSectionEmitter)='getCrazySectionData($event)'></ds-item-page-crazy-file-section>
        <ds-item-page-collections [item]="item"></ds-item-page-collections>
        <ds-item-versions class="mt-2" [item]="item"></ds-item-versions>
        <div class="button-row bottom" *ngIf="fromSubmissionObject">
          <div class="text-right">
            <button class="btn btn-outline-secondary mr-1" (click)="back()"><i
                    class="fas fa-arrow-left"></i> {{'item.page.return' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ds-error *ngIf="itemRD?.hasFailed" message="{{'error.item' | translate}}"></ds-error>
  <ds-themed-loading *ngIf="itemRD?.isLoading" message="{{'loading.item' | translate}}"></ds-themed-loading>
</div>
