import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BitstreamDataService } from '../../../../core/data/bitstream-data.service';

import { Bitstream } from '../../../../core/shared/bitstream.model';
import { Item } from '../../../../core/shared/item.model';
import { followLink } from '../../../../shared/utils/follow-link-config.model';
import { FileSectionComponent } from '../../../simple/field-components/file-section/file-section.component';
import { PaginationComponentOptions } from '../../../../shared/pagination/pagination-component-options.model';
import { PaginatedList } from '../../../../core/data/paginated-list.model';
import { RemoteData } from '../../../../core/data/remote-data';
import { switchMap, tap } from 'rxjs/operators';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { hasValue, isEmpty, isNotEmpty } from '../../../../shared/empty.util';
import { PaginationService } from '../../../../core/pagination/pagination.service';
import { ResourcePolicyDataService } from 'src/app/core/resource-policy/resource-policy-data.service';
import { ResourcePolicy } from 'src/app/core/resource-policy/models/resource-policy.model';
import { getAllSucceededRemoteData } from 'src/app/core/shared/operators';
import { ResourcePolicyCheckboxEntry } from 'src/app/shared/resource-policies/entry/resource-policy-entry.component';
import { AppConfig, APP_CONFIG } from 'src/config/app-config.interface';
import { DSONameService } from 'src/app/core/breadcrumbs/dso-name.service';

/**
 * This component renders the file section of the crazy item
 * inside a 'ds-metadata-field-wrapper' component.
 */

@Component({
  selector: 'ds-item-page-crazy-file-section',
  styleUrls: ['./crazy-file-section.component.scss'],
  templateUrl: './crazy-file-section.component.html'
})
export class CrazyFileSectionComponent extends FileSectionComponent implements OnInit {

  @Input() item: Item;

  label: string;

  originals$: Observable<RemoteData<PaginatedList<Bitstream>>>;
  licenses$: Observable<RemoteData<PaginatedList<Bitstream>>>;

  originalOptions = Object.assign(new PaginationComponentOptions(), {
    id: 'obo',
    currentPage: 1,
    pageSize: this.appConfig.item.bitstream.pageSize
  });

  licenseOptions = Object.assign(new PaginationComponentOptions(), {
    id: 'lbo',
    currentPage: 1,
    pageSize: this.appConfig.item.bitstream.pageSize
  });

  @Output() crazyFileSectionEmitter: EventEmitter<any> = new EventEmitter();

  crazyFileSectionEmitData = {'originals' : 0,
                              'licenses': 0,
                              'originalFilePolicies': []};

  /**
   * Array to track all subscriptions and unsubscribe them onDestroy
   * @type {Array}
   */
  private subs: Subscription[] = [];

  /**
   * The list of policies for given resource
   * @type {BehaviorSubject<ResourcePolicyCheckboxEntry[]>}
   */
  private resourcePoliciesEntries$: BehaviorSubject<ResourcePolicyCheckboxEntry[]> =
    new BehaviorSubject<ResourcePolicyCheckboxEntry[]>([]);

  /**
   * Array to track all resource-policy subscriptions and unsubscribe them onDestroy
   * @type {Array}
   */
  private filePolicySubs: Subscription[] = [];

  constructor(
    bitstreamDataService: BitstreamDataService,
    protected notificationsService: NotificationsService,
    protected translateService: TranslateService,
    protected paginationService: PaginationService,
    public dsoNameService: DSONameService,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    private resourcePolicyService: ResourcePolicyDataService,
    private cdr: ChangeDetectorRef
  ) {
    super(bitstreamDataService, notificationsService, translateService, dsoNameService, appConfig);
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize(): void {
    this.originals$ = this.paginationService.getCurrentPagination(this.originalOptions.id, this.originalOptions).pipe(
      switchMap((options: PaginationComponentOptions) => this.bitstreamDataService.findAllByItemAndBundleName(
        this.item,
        'ORIGINAL',
        {elementsPerPage: options.pageSize, currentPage: options.currentPage},
        true,
        true,
        followLink('format'),
        followLink('thumbnail'),
      )),
      tap((rd: RemoteData<PaginatedList<Bitstream>>) => {
          if (hasValue(rd.errorMessage)) {
            this.notificationsService.error(this.translateService.get('file-section.error.header'), `${rd.statusCode} ${rd.errorMessage}`);
          }
        }
      )
    );
    // for testing purposes
    // this.originals$.subscribe(res => console.log((res)));

    this.licenses$ = this.paginationService.getCurrentPagination(this.licenseOptions.id, this.licenseOptions).pipe(
      switchMap((options: PaginationComponentOptions) => this.bitstreamDataService.findAllByItemAndBundleName(
        this.item,
        'LICENSE',
        {elementsPerPage: options.pageSize, currentPage: options.currentPage},
        true,
        true,
        followLink('format'),
        followLink('thumbnail'),
      )),
      tap((rd: RemoteData<PaginatedList<Bitstream>>) => {
          if (hasValue(rd.errorMessage)) {
            this.notificationsService.error(this.translateService.get('file-section.error.header'), `${rd.statusCode} ${rd.errorMessage}`);
          }
        }
      )
    );

    this.subs.push(this.originals$.subscribe((data) => {
      if (data.timeCompleted) {
        this.crazyFileSectionEmitData.originals = isNaN(data.payload.pageInfo.totalElements) ? 0 : data.payload.pageInfo.totalElements;
      }
      if (isNotEmpty(data.payload) && isNotEmpty(data.payload.page)) {
        this.filePolicySubs.push(this.resourcePolicyService.searchByResource(
          data.payload.page[0].uuid, null, false, true,
          followLink('eperson'), followLink('group')
        ).pipe(
          getAllSucceededRemoteData()
        ).subscribe((result) => {
          const entries = result.payload.page.map((policy: ResourcePolicy) => ({
            id: policy.id,
            policy: policy,
            checked: false
          }));
          this.resourcePoliciesEntries$.next(entries);
          this.crazyFileSectionEmitData.originalFilePolicies = entries;
          // TODO detectChanges still needed?
          this.cdr.detectChanges();
        }));
      }
    }));

    this.subs.push(this.licenses$.subscribe((data) => {
      if (data.timeCompleted) {
        this.crazyFileSectionEmitData.licenses = isNaN(data.payload.pageInfo.totalElements) ? 0 : data.payload.pageInfo.totalElements;
      }
      if (isNotEmpty(data.payload) && isNotEmpty(data.payload.page)) {
        this.filePolicySubs.push(this.resourcePolicyService.searchByResource(
          data.payload.page[0].uuid, null, false, true,
          followLink('eperson'), followLink('group')
        ).pipe(
          getAllSucceededRemoteData()
        ).subscribe((result) => {
          const entries = result.payload.page.map((policy: ResourcePolicy) => ({
            id: policy.id,
            policy: policy,
            checked: false
          }));
          this.resourcePoliciesEntries$.next(entries);
          // TODO detectChanges still needed?
          this.cdr.detectChanges();
        }));
      }
    }));
    this.crazyFileSectionEmitter.emit(this.crazyFileSectionEmitData);

  }

  hasValuesInBundle(bundle: PaginatedList<Bitstream>) {
    return hasValue(bundle) && !isEmpty(bundle.page);
  }

  /**
   * Return all resource's policies
   *
   * @return an observable that emits all resource's policies
   */
  getResourcePolicies(): Observable<ResourcePolicyCheckboxEntry[]> {
    return this.resourcePoliciesEntries$.asObservable();
  }

  ngOnDestroy(): void {
    this.paginationService.clearPagination(this.originalOptions.id);
    this.paginationService.clearPagination(this.licenseOptions.id);
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
    this.filePolicySubs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
    this.resourcePoliciesEntries$ = null;
  }

}
