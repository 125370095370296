import { Component } from '@angular/core';
import { ThemedComponent } from '../../shared/theme-support/themed.component';
import { CrazyItemPageComponent } from './crazy-item-page.component';

/**
 * Themed wrapper for CrazyItemPageComponent
 */
@Component({
  selector: 'ds-themed-crazy-item-page',
  styleUrls: [],
  templateUrl: './../../shared/theme-support/themed.component.html',
})
export class ThemedCrazyItemPageComponent extends ThemedComponent<CrazyItemPageComponent> {
  protected getComponentName(): string {
    return 'CrazyItemPageComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/item-page/crazy/crazy-item-page.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./crazy-item-page.component`);
  }
}
