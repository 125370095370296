import { Component, Input } from '@angular/core';
import { DSpaceObject } from '../../../../../core/shared/dspace-object.model';

@Component({
  selector: 'ubmz-dc-type-badge',
  templateUrl: './dc-type-badge.component.html'
})
/**
 * Component rendering the dc.type as a badge
 */
export class DcTypeBadgeComponent {

  private _object: DSpaceObject;
  private _dcTypeMessage: string;

  /**
   * The component used to retrieve the dc.type from
   */
  @Input() set object(object: DSpaceObject) {
    this._object = object;
    const resourceDcTypeValue = (object.metadata && object.metadata['dc.type'] && object.metadata['dc.type'][0].value) ? object.metadata['dc.type'][0].value : 'no-dc-type.listelement.badge';
    this._dcTypeMessage = resourceDcTypeValue;
  }

  get object(): DSpaceObject {
    return this._object;
  }

  get dcTypeMessage(): string {
    return this._dcTypeMessage;
  }
}
