<ds-metadata-field-wrapper [label]="label | translate">
  <ng-container *ngFor="let mdValue of mdValues; let last=last; let i=index">
    <span *ngIf="isDoi(mdValue.value)">
      <a class="dont-break-out" [href]="'https://doi.org/' + mdValue.value" target="_blank">
        {{ linktext || 'https://doi.org/' + mdValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span>
      </a>
    </span>
    <span *ngIf="!isDoi(mdValue.value)">
      {{ mdValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span>
    </span>
  </ng-container>
</ds-metadata-field-wrapper>
