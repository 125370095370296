<div class="background-image-container mt-ncs">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="flex-wrap">
        <div class="headline">
          <img src="assets/jgu/images/gutenberg_open_science_nobg_white_no_text.svg" alt="">
          <h1 class="display-3">Gutenberg <b>Open</b> Science</h1>
        </div>
        <h2 [innerHTML]="'home.background-image.text1' | translate"></h2>
      </div>
      <h3 class="typewriter">{{"home.background-image.text2" | translate}}</h3>
    </div>
  </div>
  <picture class="background-image">
    <source type="image/webp" srcset="assets/jgu/images/banner_colored.webp 2000w, assets/jgu/images/banner-half_colored.webp 1200w, assets/jgu/images/banner-tall_colored.webp 768w">
    <source type="image/jpg" srcset="assets/jgu/images/banner_colored.jpg 2000w, assets/jgu/images/banner-half_colored.jpg 1200w, assets/jgu/images/banner-tall_colored.jpg 768w">
    <img alt="" [src]="'assets/jgu/images/banner_colored.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
  <!-- <small class="credits">Image by <a href="https://...">@...</a></small> -->
  <!-- #3767 Hide DINI logo until new certification -->
  <!-- <div class="overlay-logo">
    <a href="https://doi.org/10.18452/24678" target="_blank">
      <img src="assets/jgu/images/dini-zertifikat-2022_less_opaque.png" [attr.alt]="'home.background-image.overlay.dini-logo.alt' | translate" [title]="'home.background-image.overlay.dini-logo.title' | translate">
    </a>
  </div> -->
</div>
