<footer class="text-lg-start">
  <div class="top-footer p-1 d-flex justify-content-end">
    <a href="https://www.uni-mainz.de/" target="_blank">
      <img src="assets/jgu/images/jgu_logo_quer_cmyk.svg" class="jgu-logo" alt="JGU">
    </a>
  </div>
  <div class="bottom-footer p-1 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0">
        <li>
          <a class="text-white" href="javascript:void(0);"
          (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
            routerLink="info/legal-notice">{{ 'footer.link.legal-notice' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
            routerLink="info/contact">{{ 'footer.link.contact' | translate}}</a>
        </li>
        <li *ngIf="showPrivacyPolicy">
          <a class="text-white"
            routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li *ngIf="showEndUserAgreement">
          <a class="text-white"
            routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
        </li>
      </ul>
    </div>
  </div>
</footer>
