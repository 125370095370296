import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrazyItemPageEnabledGuard implements CanActivate {

  /**
   * @constructor
   */
  constructor(private router: Router) {
  }

  /**
   * True when crazy mode is enabled
   * UrlTree will redirect to full item page when crazy mode is disabled
   * @method canActivate
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (environment.ubmz.crazyMode) {
      return observableOf(true);
    } else {
      this.router.navigate(['/items/' + route.params.id + '/full']);
      return observableOf(false);
    }
  }

}
