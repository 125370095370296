<ds-themed-results-back-button *ngIf="showBackButton | async" [back]="back"></ds-themed-results-back-button>
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer id="iiif-viewer"
      [object]="object"
      [searchable]="iiifSearchEnabled"
      [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>

<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <ds-dso-edit-menu></ds-dso-edit-menu>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!(mediaViewer.image || mediaViewer.video)">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <div *ngIf="mediaViewer.image || mediaViewer.video" class="mb-2">
      <ds-themed-media-viewer [item]="object"></ds-themed-media-viewer>
    </div>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
      [parentItem]="object"
      [itemType]="'Person'"
      [metadataFields]="['dc.contributor.author', 'dc.creator']"
      [label]="'relationships.isAuthorOf' | translate">
    </ds-themed-metadata-representation-list>
    <ds-themed-metadata-representation-list
      [parentItem]="object"
      [itemType]="'Person'"
      [metadataFields]="['dc.contributor.editor']"
      [label]="'item.page.editors' | translate">
    </ds-themed-metadata-representation-list>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journal.title']"
      [label]="'item.page.journal-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journal.identifier.issn']"
      [label]="'item.page.journal-issn'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['journalvolume.identifier.name']"
      [label]="'item.page.volume-title'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.publisher']"
      [label]="'item.page.publisher'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.rights', 'dc.rights.uri']"
      [label]="'item.page.dc-rights' | translate"
      [urlRegex]="'(http[s]?://*)'">
    </ds-generic-item-page-field>
  </div>

  <div class="col-xs-12 col-md-6">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-badges *ngIf="showLabel" [object]="object" [context]="badgeContext" [showAccessStatus]="true"></ds-themed-badges>
    </ds-metadata-field-wrapper>

    <ds-metadata-field-wrapper *ngIf="!abstractEmpty">
      <!-- We can't use the ds-item-page-abstract-field component as we need the abstract to be collapsible -->
      <ds-truncatable [id]="object.id">
        <h2 class="simple-view-element-header">{{'item.page.abstract' | translate}}</h2>
        <ds-truncatable-part [id]="object.id" [minLines]="3">
          <span [ngClass]="{'text-muted': !object.firstMetadataValue('dc.description.abstract')}"
            [innerHTML]="(object.firstMetadataValue('dc.description.abstract'))">
          </span>
        </ds-truncatable-part>
      </ds-truncatable>
    </ds-metadata-field-wrapper>

    <ds-item-page-uri-field [item]="object"
      [fields]="['dc.identifier.doi']"
      [label]="'DOI'">
    </ds-item-page-uri-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'item.page.description'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.subject']"
      [separator]="', '"
      [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.identifier.citation']"
      [label]="'item.page.citation'">
    </ds-generic-item-page-field>
    <ds-item-page-uri-field [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'URI'">
    </ds-item-page-uri-field>
    <ds-item-page-mixed-uri-field *ngIf="hasJguJournalMetadata"
      [item]="object"
      [fields]="['jgu.journal.title', 'jgu.journal.volume', 'jgu.journal.issue', 'jgu.publisher.name', 'jgu.publisher.place', 'jgu.publisher.year', 'jgu.publisher.doi']"
      [label]="'item.page.jgu-journal' | translate"
      [separator]="separator">
    </ds-item-page-mixed-uri-field>
    <ds-item-page-mixed-uri-field *ngIf="hasJguBookMetadata"
      [item]="object"
      [fields]="['jgu.book.title','jgu.book.editor', 'jgu.book.doi', 'jgu.publisher.name', 'jgu.publisher.place', 'jgu.publisher.year', 'jgu.publisher.doi']"
      [label]="'item.page.jgu-book' | translate"
      [separator]="separator">
    </ds-item-page-mixed-uri-field>
    <ds-item-page-mixed-uri-field [item]="object"
      [fields]="['jgu.relation.Cites', 'jgu.relation.Compiles', 'jgu.relation.Continues', 'jgu.relation.Describes', 'jgu.relation.Documents',
                'jgu.relation.HasMetadata', 'jgu.relation.HasPart', 'jgu.relation.HasVersion', 'jgu.relation.IsCitedBy', 'jgu.relation.IsCompiledBy',
                'jgu.relation.IsContinuedBy', 'jgu.relation.IsDerivedFrom', 'jgu.relation.IsDescribedBy', 'jgu.relation.IsDocumentedBy', 'jgu.relation.IsIdenticalTo',
                'jgu.relation.IsMetadataFor', 'jgu.relation.IsNewVersionOf', 'jgu.relation.IsOriginalFormOf', 'jgu.relation.IsPartOf', 'jgu.relation.IsPreviousVersionOf',
                'jgu.relation.IsPublishedIn', 'jgu.relation.IsReferencedBy', 'jgu.relation.IsRequiredBy', 'jgu.relation.IsReviewedBy', 'jgu.relation.IsSourceOf',
                'jgu.relation.IsSupplementedBy', 'jgu.relation.IsSupplementTo', 'jgu.relation.IsVariantFormOf', 'jgu.relation.IsVersionOf', 'jgu.relation.References',
                'jgu.relation.Requires', 'jgu.relation.Reviews', 'jgu.relation.SupplementedBy', 'jgu.relation']"
      [label]="'item.page.relation' | translate">
    </ds-item-page-mixed-uri-field>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']" role="button">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
