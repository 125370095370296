<div class="br-container fe-container">
  <div class="br-info">FE-Branch: {{feBuildBranchName}}</div>
  <div class="br-info">FE-Tag: {{feBuildTag}}</div>
  <div class="br-info">FE-Time: {{feBuildTime}}</div>
  <div class="br-info">FE-Req: {{feBuildRequestedBy}}</div>
  <div class="br-info">FE-Env: {{feBuildEnvironment}}</div>
</div>

<div class="br-container be-container">
  <div class="br-info">BE-Branch: {{beBuildBranchName | async }}</div>
  <div class="br-info">BE-Tag: {{beBuildTag | async }}</div>
  <div class="br-info">BE-Time: {{beBuildTime | async }}</div>
  <div class="br-info">BE-Req: {{beBuildRequestedBy | async }} </div>
  <div class="br-info">BE-Env: {{beBuildEnvironment | async }} </div>
</div>
