<header id="main-site-header">
  <div class="container h-100 d-flex flex-row flex-wrap align-items-center justify-content-between gapx-3 gapy-2" id="main-site-header-container">
    <ubmz-branch-info *ngIf="displayBuildInfos"></ubmz-branch-info>
    <!-- Logo and navbar wrapper -->
    <div id="header-left"
      [attr.role]="(isMobile$ | async) ? 'navigation' : 'presentation'"
      [attr.aria-label]="(isMobile$ | async) ? ('nav.main.description' | translate) : null"
      class="h-100 flex-fill d-flex flex-row flex-nowrap justify-content-start align-items-center gapx-3">
      <a class="navbar-brand m-2" href="https://ub.uni-mainz.de" target="_blank" [attr.aria-label]="'home.ub-link' | translate">
        <img id="header-logo" src="assets/jgu/images/jgu-ub-logo-mit_jgu-rgb.svg" [attr.alt]="'menu.header.image.ub-logo' | translate"/>
      </a>
      <nav *ngIf="(isMobile$ | async) !== true" class="navbar navbar-expand p-0 align-items-stretch align-self-stretch" id="desktop-navbar" [attr.aria-label]="'nav.main.description' | translate">
        <ds-themed-navbar></ds-themed-navbar>
      </nav>
    </div>
    <!-- Search bar and other menus -->
    <div id="header-right" class="h-100 d-flex flex-row flex-nowrap justify-content-end align-items-center gapx-1 ml-auto">
      <ds-themed-search-navbar></ds-themed-search-navbar>
      <div role="menubar" class="h-100 d-flex flex-row flex-nowrap align-items-center gapx-1">
        <ds-themed-lang-switch></ds-themed-lang-switch>
        <ds-context-help-toggle></ds-context-help-toggle>
        <ubmz-help-page></ubmz-help-page>
        <ds-impersonate-navbar></ds-impersonate-navbar>
        <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
      </div>

      <div id="mobile-navbar-toggler" class="d-block d-lg-none ml-3" *ngIf="(isMobile$ | async)">
        <button id="navbar-toggler" class="btn" type="button" (click)="toggleNavbar()"
                [attr.aria-label]="'nav.toggle' | translate" aria-controls="collapsible-mobile-navbar" [attr.aria-expanded]="(isNavBarCollapsed$ | async) !== true">
          <span class="fas fa-bars fa-fw fa-xl toggler-icon" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </div>
</header>
