<div class="container w-100 h-100">
  <div class="text-center mt-5 row justify-content-center">
    <div>
      <img class="mb-4 login-logo" src="assets/images/dspace-logo.svg" [alt]="'repository.image.logo' | translate">
      <h1 class="h3 mb-0 font-weight-normal">{{"login.form.header" | translate}}</h1>
      <ds-themed-log-in
      [isStandalonePage]="true"></ds-themed-log-in>
    </div>
  </div>
</div>
