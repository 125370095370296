import { UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { DynamicDsYearPickerModel } from './year-picker.model';
import { hasValue } from '../../../../../empty.util';
import {
  DynamicFormControlComponent,
  DynamicFormLayoutService,
  DynamicFormValidationService
} from '@ng-dynamic-forms/core';
import { DOCUMENT } from '@angular/common';


export type YearPickerFieldType = '_year';

@Component({
  selector: 'ds-year-picker',
  styleUrls: ['./year-picker.component.scss'],
  templateUrl: './year-picker.component.html',
})

export class DsYearPickerComponent extends DynamicFormControlComponent implements OnInit {
  @Input() bindId = true;
  @Input() group: UntypedFormGroup;
  @Input() model: DynamicDsYearPickerModel;
  @Input() legend: string;

  @Output() selected = new EventEmitter<number>();
  @Output() remove = new EventEmitter<number>();
  @Output() blur = new EventEmitter<any>();
  @Output() change = new EventEmitter<any>();
  @Output() focus = new EventEmitter<any>();

  initialYear: number;

  year: any;
  month: any;
  day: any;

  minYear: 0;
  maxYear: number;

  yearPlaceholder = 'year';

  private readonly fields: YearPickerFieldType[] = ['_year'];

  constructor(protected layoutService: DynamicFormLayoutService,
              protected validationService: DynamicFormValidationService,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private _document: Document
  ) {
    super(layoutService, validationService);
  }

  ngOnInit() {
    const now = new Date();
    this.initialYear = now.getUTCFullYear();

    if (this.model && this.model.value !== null) {
      this.initialYear = parseInt(this.model.value.toString(), 10);
      this.year = this.initialYear;
    }

    this.maxYear = now.getUTCFullYear() + 100;
    }

  onBlur(event) {
    this.blur.emit();
  }

  onChange(event) {
    // update year
    if (event.value !== null) {
      this.year = event.value;
    } else {
      this.year = undefined;
    }

    // update value
    let value = null;
    if (hasValue(this.year)) {
      let yyyy = this.year.toString();
      while (yyyy.length < 4) {
        yyyy = '0' + yyyy;
      }
      value = yyyy;
    }

    this.model.value = value;
    this.change.emit(value);
  }

  onFocus(event) {
    this.focus.emit(event);
  }

}
