import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { AccessStatusObject } from './access-status.model';
import { hasValue } from '../../../../empty.util';
import { environment } from 'src/environments/environment';
import { AccessStatusDataService } from 'src/app/core/data/access-status-data.service';
import { DSpaceObject } from '../../../../../core/shared/dspace-object.model';
import { Item } from '../../../../../core/shared/item.model';
import { ITEM } from '../../../../../core/shared/item.resource-type';
import { ResourcePolicyDataService } from 'src/app/core/resource-policy/resource-policy-data.service';
import { getAllSucceededRemoteData } from 'src/app/core/shared/operators';
import { FeatureID } from 'src/app/core/data/feature-authorization/feature-id';
import { AuthorizationDataService } from 'src/app/core/data/feature-authorization/authorization-data.service';
import { followLink } from 'src/app/shared/utils/follow-link-config.model';

@Component({
  selector: 'ds-access-status-badge',
  templateUrl: './access-status-badge.component.html',
  styleUrls: ['./access-status-badge.component.scss']
})
/**
 * Component rendering the access status of an item as a badge
 */
export class AccessStatusBadgeComponent implements OnInit, OnDestroy {

  @Input() object: DSpaceObject;
  accessStatus$: Observable<string>;

  /**
   * Whether to show the access status badge or not
   */
  showAccessStatus: boolean;

  /**
   * Value based stylesheet class for access status badge
   */
  accessStatusClass: string;

  /**
   * List of subscriptions
   */
  subs: Subscription[] = [];

  embargoStatus: string;

  embargoStartDate: string;

  isEmbargoed: string;

  /**
   * Whether the current user is an admin or not
   */
  isAdmin$: Observable<boolean>;

  /**
   * Initialize instance variables
   *
   * @param {AccessStatusDataService} accessStatusDataService
   */
  constructor(private accessStatusDataService: AccessStatusDataService,
              private resourcePolicyService: ResourcePolicyDataService,
              protected authorizationService: AuthorizationDataService,
              private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.showAccessStatus = environment.item.showAccessStatuses;
    if (this.object.type.toString() !== ITEM.value || !this.showAccessStatus || this.object == null) {
      // Do not show the badge if the feature is inactive or if the item is null.
      return;
    }

    const item = this.object as Item;
    if (item.accessStatus == null) {
      // In case the access status has not been loaded, do it individually.
      item.accessStatus = this.accessStatusDataService.findAccessStatusFor(item);
    }
    this.accessStatus$ = item.accessStatus.pipe(
      map((accessStatusRD) => {
        if (accessStatusRD.statusCode !== 401 && hasValue(accessStatusRD.payload)) {
          return accessStatusRD.payload;
        } else {
          return [];
        }
      }),
      map((accessStatus: AccessStatusObject) => hasValue(accessStatus.status) ? accessStatus.status : 'unknown'),
      map((status: string) => this.embargoStatus = status),
      map((status: string) => `access-status.${status.toLowerCase()}.listelement.badge`),
      catchError(() => observableOf('access-status.unknown.listelement.badge'))
    );

    // stylesheet based on the access status value
    this.subs.push(
      this.accessStatus$.pipe(
        map((accessStatusClass: string) => accessStatusClass.replace(/\./g, '-'))
      ).subscribe((accessStatusClass: string) => {
        this.accessStatusClass = accessStatusClass;
      })
    );

    this.isEmbargoed = item.firstMetadataValue('jgu.rights.accessrights');
    if (this.isEmbargoed === 'embargoedAccess') {
      this.isAdmin$ = this.authorizationService.isAuthorized(FeatureID.AdministratorOf);
      this.isAdmin$.subscribe((isAdmin => {
        if (isAdmin) {
          this.subs.push(this.resourcePolicyService.searchByResource(
            item.uuid, null, false, true,
            followLink('eperson'), followLink('group'),
          ).pipe(
            getAllSucceededRemoteData(),
          ).subscribe((result) => {
            if (this.embargoStatus === 'embargo') {
              this.embargoStartDate = result.payload.page[0].startDate;
              this.cdr.detectChanges();
            }
          }));
        }
      }));
    }
  }

  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }
}
