import { AfterContentInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Item } from '../../../../../../../app/core/shared/item.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import {
  listableObjectComponent
} from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import {
  UntypedItemComponent as BaseComponent
} from '../../../../../../../app/item-page/simple/item-types/untyped-item/untyped-item.component';

/**
 * Component that represents an untyped Item page
 */
@listableObjectComponent(Item, ViewMode.StandalonePage, Context.Any, 'jgu')
@Component({
  selector: 'ds-untyped-item',
  styleUrls: ['./untyped-item.component.scss'],
  // styleUrls: ['../../../../../../../app/item-page/simple/item-types/untyped-item/untyped-item.component.scss'],
  templateUrl: './untyped-item.component.html',
  // templateUrl: '../../../../../../../app/item-page/simple/item-types/untyped-item/untyped-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UntypedItemComponent extends BaseComponent implements AfterContentInit {
  /**
   * Whether to show the badge label or not
   */
    @Input() showLabel = true;

  /**
   * Represents the badge context
   */
    @Input() badgeContext: Context;

  /**
   * The value's separator
   */
  public separator = ', ';

  public abstractEmpty = true;

  public hasJguBookMetadata = false;

  public hasJguJournalMetadata = false;

  ngAfterContentInit(): void {
    this.abstractEmpty = (this.object.firstMetadataValue('dc.description.abstract') && this.object.firstMetadataValue('dc.description.abstract').length > 0) ? false : true;
    this.hasJguBookMetadata = (this.object.firstMetadataValue('jgu.book.title') || this.object.firstMetadataValue('jgu.book.editor') || this.object.firstMetadataValue('jgu.book.doi')) ? true : false;
    this.hasJguJournalMetadata = (this.object.firstMetadataValue('jgu.journal.title') || this.object.firstMetadataValue('jgu.journal.volume') || this.object.firstMetadataValue('jgu.journal.issue')) ? true : false;
  }

}
