<div class="container">
  <div class="card border-0">
    <div class="card-body">
      <ds-alert
        *ngIf="titleMessage"
        [type]="AlertType.Info"
        [content]="titleMessage"
        class="d-block pb-3">
      </ds-alert>

      <!-- Should be *ngIf instead of class d-none, but that breaks the #embargoAccessCmp reference-->
      <div [ngClass]="{'d-none': !bulkAccessPolicyEnabled}">
        <div class="row mt-5">
          <div class="col-12 border-right d-flex flex-column justify-content-between">

            <div>
              <div class="d-flex align-items-center">
                <h2 class="h3 mb-0 mr-4">
                  {{ 'access-control-embargo-header-toggle' | translate }}
                </h2>
                <ui-switch
                  [ariaLabel]="(state.embargo.toggleStatus ? 'access-control-embargo-toggle.disable' : 'access-control-embargo-toggle.enable') | translate"
                  [(ngModel)]="state.embargo.toggleStatus"
                  (ngModelChange)="handleStatusChange('embargo', $event)">
                </ui-switch>
              </div>

              <fieldset class="row mt-3">
                <legend class="h4 col-12 col-md-3">
                  {{ 'access-control-mode' | translate }}
                </legend>
                <div class="col-12 col-md-8">
                  <div class="form-check">
                    <input class="form-check-input" type="radio"
                          name="embargoMode" id="embargoReplace" value="replace"
                          [disabled]="!state.embargo.toggleStatus"
                          [(ngModel)]="state.embargo.accessMode">
                    <label class="form-check-label" for="embargoReplace">
                      {{'access-control-replace-all' | translate}}
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>

            <div>
              <h5 class="mt-3">{{'access-control-access-conditions' | translate}}</h5>

              <ds-access-control-array-form
                #embargoAccessCmp
                [type]="'embargo'"
                [mode]="state.embargo.accessMode"
                [dropdownOptions]="(dropdownData$ | async)?.embargoAccessConditionOptions || []">
              </ds-access-control-array-form>
            </div>

          </div>
        </div>
      </div>
      <!-- Should be *ngIf instead of class d-none, but that breaks the #itemAccessCmp and #bitstreamAccessCmp reference-->
      <div [ngClass]="{'d-none': bulkAccessPolicyEnabled}">
        <div class="row mt-5">
          <div class="col-12 col-md-6 border-right d-flex flex-column justify-content-between">

          <div>
            <div class="d-flex align-items-center">
              <h2 class="h3 mb-0 mr-4">
                {{ 'access-control-item-header-toggle' | translate }}
              </h2>
              <ui-switch
                [ariaLabel]="(state.bitstream.toggleStatus ? 'access-control-item-toggle.disable' : 'access-control-item-toggle.enable') | translate"
                [(ngModel)]="state.item.toggleStatus"
                (ngModelChange)="handleStatusChange('item', $event)">
              </ui-switch>
            </div>

            <fieldset class="row mt-3">
              <legend class="h4 col-12 col-md-3">
                {{ 'access-control-mode' | translate }}
              </legend>
              <div class="col-12 col-md-8">
                <div class="form-check">
                  <input class="form-check-input" type="radio"
                         name="itemMode" id="itemReplace" value="replace"
                         [disabled]="!state.item.toggleStatus"
                         [(ngModel)]="state.item.accessMode">
                  <label class="form-check-label" for="itemReplace">
                    {{'access-control-replace-all' | translate}}
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio"
                         name="itemMode" id="itemAdd" value="add"
                         [disabled]="!state.item.toggleStatus"
                         [(ngModel)]="state.item.accessMode">
                  <label class="form-check-label" for="itemAdd">
                    {{'access-control-add-to-existing' | translate}}
                  </label>
                </div>
              </div>
            </fieldset>
          </div>

          <div>
            <h3 class="h4 mt-3">{{'access-control-access-conditions' | translate}}</h3>

            <ds-access-control-array-form
              #itemAccessCmp
              [type]="'item'"
              [mode]="state.item.accessMode"
              [dropdownOptions]="(dropdownData$ | async)?.itemAccessConditionOptions || []">
            </ds-access-control-array-form>
          </div>

        </div>

        <div class="col-12 col-md-6 d-flex flex-column justify-content-between">

          <div>
            <div class="d-flex align-items-center">
              <h2 class="h3 mb-0 mr-4">
                {{'access-control-bitstream-header-toggle' | translate}}
              </h2>
              <ui-switch
                [ariaLabel]="(state.bitstream.toggleStatus ? 'access-control-bitstream-toggle.disable' : 'access-control-bitstream-toggle.enable') | translate"
                [(ngModel)]="state.bitstream.toggleStatus"
                (ngModelChange)="handleStatusChange('bitstream', $event)">
              </ui-switch>
            </div>

            <div *ngIf="showLimitToSpecificBitstreams" class="row mt-3">
            <fieldset class="col-12">
              <legend class="h4">
                {{'access-control-limit-to-specific' | translate}}
              </legend>
              <div class="form-check">
                <input class="form-check-input" type="radio"
                       name="changesLimit" id="processAll" value="all"
                       [disabled]="!state.bitstream.toggleStatus"
                       [(ngModel)]="state.bitstream.changesLimit">
                <label class="form-check-label" for="processAll">
                  {{'access-control-process-all-bitstreams' | translate}}
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input mt-2" type="radio"
                       name="changesLimit" id="processSelected" value="selected"
                       [disabled]="!state.bitstream.toggleStatus"
                       [(ngModel)]="state.bitstream.changesLimit">
                <label class="form-check-label" for="processSelected">
                  {{ state.bitstream.selectedBitstreams.length }}
                  {{'access-control-bitstreams-selected' | translate}}

                  <button
                    *ngIf="itemRD"
                    [attr.aria-label]="'access-control-bitstreams-select' | translate"
                    [disabled]="!state.bitstream.toggleStatus || state.bitstream.changesLimit !== 'selected'"
                    (click)="openSelectBitstreamsModal(itemRD.payload)"
                    class="btn btn-outline-dark border-0" type="button">
                    <i class="fa fa-search"></i>
                  </button>

                </label>
              </div>
            </fieldset>
            </div>
          </div>

          <fieldset class="row mt-3">
            <legend class="h4 col-12 col-md-3">
              {{'access-control-mode' | translate}}
            </legend>
            <div class="col-12 col-md-8">
              <div class="form-check">
                <input class="form-check-input" type="radio"
                       name="bitstreamMode" id="bitstreamReplace" value="replace"
                       [disabled]="!state.bitstream.toggleStatus"
                       [(ngModel)]="state.bitstream.accessMode">
                <label class="form-check-label" for="bitstreamReplace">
                  {{'access-control-replace-all' | translate}}
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio"
                       name="bitstreamMode" id="bitstreamAdd" value="add"
                       [disabled]="!state.bitstream.toggleStatus"
                       [(ngModel)]="state.bitstream.accessMode">
                <label class="form-check-label" for="bitstreamAdd">
                  {{'access-control-add-to-existing' | translate}}
                </label>
              </div>
            </div>
          </fieldset>

          <div>
            <h3 class="h4 mt-3">{{'access-control-access-conditions' | translate}}</h3>

            <ds-access-control-array-form
              #bitstreamAccessCmp
              [type]="'bitstream'"
              [mode]="state.bitstream.accessMode"
              [dropdownOptions]="(dropdownData$ | async)?.bitstreamAccessConditionOptions || []">
            </ds-access-control-array-form>
            </div>
          </div>
        </div>
      </div>

      <hr *ngIf="showSubmit">

      <div *ngIf="showSubmit" class="d-flex justify-content-end">
        <button class="btn btn-outline-primary mr-3" (click)="reset()" type="button">
          {{ 'access-control-cancel' | translate }}
        </button>
        <button class="btn btn-primary"
                [disabled]="!state.item.toggleStatus && !state.bitstream.toggleStatus && !state.embargo.toggleStatus || !isEmbargoAccessConditionSet()"
                (click)="submit()" type="submit">
          {{ 'access-control-execute' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
