import { FieldParser } from './field-parser';
import {
  DynamicDsYearPickerModel,
  DynamicDsYearControlModelConfig
} from '../ds-dynamic-form-ui/models/year-picker/year-picker.model';
import { isNotEmpty } from '../../../empty.util';
import { FormFieldMetadataValueObject } from '../models/form-field-metadata-value.model';

export class YearFieldParser extends FieldParser {

  public modelFactory(fieldValue?: FormFieldMetadataValueObject | any, label?: boolean): any {
    let malformedYear = false;
    const inputYearModelConfig: DynamicDsYearControlModelConfig = this.initModel(null, false, true);
    inputYearModelConfig.legend = this.configData.label;
    inputYearModelConfig.disabled = inputYearModelConfig.readOnly;
    inputYearModelConfig.toggleIcon = 'fas fa-calendar';
    this.setValues(inputYearModelConfig as any, fieldValue);
    // Init Data and validity check
    if (isNotEmpty(inputYearModelConfig.value)) {
      const value = inputYearModelConfig.value.toString();

    }
    const yearModel = new DynamicDsYearPickerModel(inputYearModelConfig);
    yearModel.malformedYear = malformedYear;
    return yearModel;
  }
}
